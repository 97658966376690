<template>
  <div
    data-testid="addToCart"
    class="flex flex-row items-center place-content-center rounded-alt-2xl border-0 border-light bg-btn-cta-base cursor-pointer w-full md:w-fit text-center h-[25px] md:h-[36px]"
    :class="[
      props.class,
      { 'opacity-50  !cursor-not-allowed relative disabled-after': disabled },
      paddingSize === 'small' ? 'px-sm' : '',
      paddingSize === 'medium' ? 'px-sm xl:px-md' : '',
      paddingSize === 'large' ? 'px-md md:px-xl' : '',
    ]"
    @click.prevent="disabled ? () => {} : emit('click')"
  >
    <FaIcon
      v-if="btnStyle === 'icon' || btnStyle === 'iconText'"
      icon-class="far fa-shopping-cart"
      testid="addToCartIcon"
      :classes="`my-xs text-white font-bold ${
        btnStyle === 'iconText' ? 'mr-xs' : ''
      }`"
    />
    <div
      v-if="btnStyle === 'text' || btnStyle === 'iconText'"
      class="font-bold text-white text-bold tracking-[0.36px] text-lg"
    >
      {{ t(label) }}
    </div>
    <span
      v-if="loadingAnimation"
      class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
    >
      <FaIcon icon-class="fas fa-spinner-third" class="animate-spin" />
    </span>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';

const props = defineProps({
  class: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnStyle: {
    type: String as PropType<'icon' | 'text' | 'iconText'>,
    required: false,
    default: 'icon',
  },
  label: {
    type: String,
    required: false,
    default: 'shop.productList.addToCart',
  },
  paddingSize: {
    type: String as PropType<'none' | 'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
  loadingAnimation: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const { t } = useTrans();
/** Emits */
const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<style scoped lang="postcss">
.disabled-after {
  &::after {
    content: '';
    @apply bg-black absolute w-full h-full top-0 left-0 opacity-0;
  }
}
</style>
